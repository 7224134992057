import tags from "./js/tags";
import modal from "./js/modal";
import player from "./js/player";
import banners from "./js/banners";
import previews from "./js/previews";


window.COMPLACENCE = process.env.COMPLACENCE || "0";

document.addEventListener("DOMContentLoaded", () => {
  tags.init();
  banners.init();
  previews.init();
  player.init();
  modal.init();

  // Замена конкретного баннера
  const isComplacent = window.COMPLACENCE === "1"; // Проверка флага
  const bannerElement = document.querySelector(".banner_bottom .banner__image");

  if (bannerElement && isComplacent) {
    bannerElement.src = "/static/temp-bottom/temp-gorilla.jpg"; // Новый путь
    bannerElement.alt = "Compliant Banner"; // Обновлённый alt
  }
});
