import MicroModal from "micromodal";

const init = () => {
  const isShown = sessionStorage.getItem("modal-1-shown");

  if (isShown) {
    return;
  }

  setTimeout(() => {
    MicroModal.show("modal-1");
    sessionStorage.setItem("modal-1-shown", "yes");
  }, 10000);
};

document.addEventListener('DOMContentLoaded', () => {
  const isComplacent = window.COMPLACENCE === '1';
  const modalButton = document.querySelector('.modal__btn');

  if (isComplacent && modalButton) {
    modalButton.textContent = 'Закрыть';
    modalButton.setAttribute('aria-label', 'Закрыть');
  }
});

export default { init };
