import { getRandomInt } from "./utils";

const init = () => {
  const bannerImage = document.querySelector(".banner_box > img");

  if (bannerImage) {
    // Проверяем флаг COMPLACENCE
    const isComplacent = window.COMPLACENCE === "1";

    const min = 1;
    const max = isComplacent ? 1 : 4;

    bannerImage.src = `/static/banners/${getRandomInt(min, max)}.gif`;
    bannerImage.parentElement.hidden = false;
  }
};

export default { init };
